import revive_payload_client_7w7CtByxjr from "/temp/build/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lmynbpcayv4ab2khroh6art774/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_tsYNU6ZVlx from "/temp/build/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lmynbpcayv4ab2khroh6art774/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7n7AtMVsB0 from "/temp/build/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lmynbpcayv4ab2khroh6art774/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_dXo7YQ0MXC from "/temp/build/node_modules/.pnpm/nuxt-site-config@2.2.17_magicast@0.3.5_rollup@4.21.2_vite@5.4.4_@types+node@22.5.4_sass@1.78._jl6pfbjn347m3glie6uwl6ftyq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_0vu0FZ5q7u from "/temp/build/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lmynbpcayv4ab2khroh6art774/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ic8F1Frl9M from "/temp/build/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lmynbpcayv4ab2khroh6art774/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_baQD6tDpCc from "/temp/build/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lmynbpcayv4ab2khroh6art774/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_btnxe0v8vW from "/temp/build/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lmynbpcayv4ab2khroh6art774/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_PX6H6nh8yg from "/temp/build/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.2_typescript@5.6.2_vue@3.5.4_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/temp/build/.nuxt/components.plugin.mjs";
import prefetch_client_WrjsdLtIti from "/temp/build/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_lmynbpcayv4ab2khroh6art774/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_S86jtIvR2V from "/temp/build/node_modules/.pnpm/@artmizu+yandex-metrika-nuxt@1.0.4_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/@artmizu/yandex-metrika-nuxt/dist/runtime/plugin.mjs";
import nuxt3_plugin_km6tOcQn8x from "/temp/build/node_modules/.pnpm/vue-yandex-maps@2.1.4_@vue+runtime-core@3.5.4_nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@2_ximxfc5eskrw2lgckpeo66omci/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import vmask_MNloZULcpN from "/temp/build/src/plugins/vmask.ts";
import nuxtServerInit_LTWY2PUaTk from "/temp/build/src/plugins/nuxtServerInit.ts";
import scrollTop_e7v3LLiez9 from "/temp/build/src/plugins/scrollTop.ts";
import sentryInit_client_D9KT86gqsm from "/temp/build/src/plugins/sentryInit.client.ts";
export default [
  revive_payload_client_7w7CtByxjr,
  unhead_tsYNU6ZVlx,
  router_7n7AtMVsB0,
  _0_siteConfig_dXo7YQ0MXC,
  payload_client_0vu0FZ5q7u,
  navigation_repaint_client_ic8F1Frl9M,
  check_outdated_build_client_baQD6tDpCc,
  chunk_reload_client_btnxe0v8vW,
  plugin_vue3_PX6H6nh8yg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_WrjsdLtIti,
  plugin_S86jtIvR2V,
  nuxt3_plugin_km6tOcQn8x,
  vmask_MNloZULcpN,
  nuxtServerInit_LTWY2PUaTk,
  scrollTop_e7v3LLiez9,
  sentryInit_client_D9KT86gqsm
]